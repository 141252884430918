import styled from 'styled-components'
import { colors } from '@monorepo/theme'
import {
  TableContainer as MuiTableContainer,
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
} from '@material-ui/core'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const TableContainer = styled(MuiTableContainer)`
  &.MuiTableContainer-root {
    overflow-y: hidden;
  }
`

export const Table = styled(MuiTable)`
  background-color: ${colors.white};
`

export const TableHead = styled(MuiTableHead)`
  position: relative;
`

export const TableRow = styled(MuiTableRow)``

export const TableHeadRow = styled(TableRow)`
  & > .MuiTableCell-root.MuiTableCell-head:first-child {
    width: 10%;
  }

  & > .MuiTableCell-root.MuiTableCell-head:nth-child(2) {
    text-align: center;
  }

  & > .MuiTableCell-root.MuiTableCell-head:nth-child(3) {
    text-align: center;
    width: 20%;
  }

  & > .MuiTableCell-root.MuiTableCell-head:last-child {
    text-align: center;
    width: 15%;
  }
`

export const TableHeadCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    color: ${colors.slateGrey};
    font-family: PFDINText;
    font-size: 12px;
    text-transform: uppercase;
    height: 48px;
    padding: 0 16px;
  }
`

export const TbodyRow = styled(TableRow)`
  border-bottom: 1px solid #cfd4d9;
  height: 78px;

  & > .MuiTableCell-root:nth-child(2) {
    text-align: right;
  }

  & > .MuiTableCell-root:nth-child(3) {
    text-align: center;
  }

  & > .MuiTableCell-root:last-child {
    text-align: right;
  }
`

export const TBodyCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    font-family: PFDINText;
    font-size: 14px;
    padding: 0 16px;
  }
`
export const EmptyMessage = styled.div`
  text-align: center;
  padding: 1rem;
  color: ${colors.black};
  font-family: PFDINText;
  font-size: x-large;
`
