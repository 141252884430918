/* eslint-disable @typescript-eslint/no-explicit-any */
import { TableBody } from '@material-ui/core'
import {
  ChannelHelper,
  formatBoolean,
  formatterCurrencyUSD,
} from '@monorepo/infra'
import { IUnitedGetCoopAllowanceListItemResponse } from '@monorepo/interfaces'
import * as S from './styles'

export interface SectionCoOpAllowancesListProps {
  data?: IUnitedGetCoopAllowanceListItemResponse[]
  errorMessage?: string
}

const SectionCoOpAllowancesList: React.FC<SectionCoOpAllowancesListProps> = ({
  data,
  errorMessage,
}) => {
  return (
    <S.Container>
      {Array.isArray(data) && data.length ? (
        <S.TableContainer>
          <S.Table aria-label="Co-Op Table">
            <S.TableHead>
              <S.TableHeadRow>
                <S.TableHeadCell>Channel</S.TableHeadCell>
                <S.TableHeadCell>Allowance</S.TableHeadCell>
                <S.TableHeadCell>Is Recurring</S.TableHeadCell>
                <S.TableHeadCell>Recurring Allowance</S.TableHeadCell>
              </S.TableHeadRow>
            </S.TableHead>
            <TableBody>
              {data.map((item, index) => (
                <S.TbodyRow key={index}>
                  <S.TBodyCell scope="row">
                    {
                      ChannelHelper.splitMasterDealerId(item.MasterDealerId)
                        ?.channel
                    }
                  </S.TBodyCell>
                  <S.TBodyCell scope="row">
                    {formatterCurrencyUSD.format(item.Allowance || 0)}
                  </S.TBodyCell>
                  <S.TBodyCell scope="row">
                    {formatBoolean(item.IsRecurring, true)}
                  </S.TBodyCell>
                  <S.TBodyCell scope="row">
                    {formatterCurrencyUSD.format(
                      item.RecurringAllowanceAmount || 0
                    )}
                  </S.TBodyCell>
                </S.TbodyRow>
              ))}
            </TableBody>
          </S.Table>
        </S.TableContainer>
      ) : (
        <S.EmptyMessage>{errorMessage || 'There is no data'}</S.EmptyMessage>
      )}
    </S.Container>
  )
}

export default SectionCoOpAllowancesList
