import * as S from './styles'

import {
  CartHelper,
  ChannelHelper,
  OrderHelper,
  checkoutMachine,
  http,
  useCartContext,
  useChannel,
  isObject,
  KeycloakHelper,
  DealerHelper,
} from '@monorepo/infra'
import {
  CartItems,
  CartProduct,
  Order,
  OrderAddress,
  OrderItem,
  Payment,
} from '@monorepo/interfaces'
import React, { useEffect, useState } from 'react'
import {
  SectionCheckoutConfirmation,
  SectionCheckoutConfirmationProducts,
  SectionCheckoutCoop,
  SectionCheckoutPaymentEdit,
  SectionCheckoutPaymentView,
  SectionCheckoutProducts,
  SectionCheckoutShippingEdit,
  SectionCheckoutShippingView,
  SectionCheckoutTotalReview,
} from '../../organisms/SectionCheckout'
import { LogItemType, LogType, log } from '../../../services/log.service'
import { PageSpinner } from '@monorepo/components'
import { ServiceBusHelper } from '@monorepo/infra'
import { useKeycloak } from '@react-keycloak/web'
import { useMachine } from '@xstate/react'

const Checkout: React.FC = () => {
  const { items, clearAllItems } = useCartContext()
  const { keycloak } = useKeycloak()
  const user = KeycloakHelper.getTokenParsed(keycloak)
  const { selectedChannel, selectedMasterDealerId, coop, updateBalance } =
    useChannel()
  const canRequestCoop =
    !DealerHelper.hasCoop(coop) && DealerHelper.getMasterDealerCoop(coop) > 0
  const isFreeOrder =
    CartHelper.getProductSubtotal(items.products) == 0 &&
    CartHelper.getEventSubtotal(items.events) == 0
  const [state, send] = useMachine(checkoutMachine, {
    context: {
      hasCoop: DealerHelper.getCoop(coop) > 0,
      canRequestCoop,
      isFreeOrder,
    },
  })
  const [orderToSend, setOrderToSend] = useState<{
    body: { order: Order } | null
    contentType: string
  }>({
    body: null,
    contentType: 'application/json',
  })
  const [orderItems, setOrderItems] = useState<CartItems | null>(null)
  const [sentOrder, setSentOrder] = useState<Order | null>(null)
  const [loading, setLoading] = useState(false)
  const [errorHistory, setErrorHistory] = useState<[string, Date][]>([])
  const [error, setError] = useState('')
  const [coopRequestTotal, setCoopRequestTotal] = useState<number>(0)
  const isArrayEmpty = (value: unknown) =>
    !Array.isArray(value) || !value.length
  const isCartItemsEmpty = (items: CartItems) =>
    isArrayEmpty(items.products) && isArrayEmpty(items.events)

  useEffect(() => {
    async function sendOrder() {
      if (
        !orderToSend?.body ||
        !isObject(orderToSend?.body) ||
        Object.keys(orderToSend.body).length === 0 ||
        isArrayEmpty(orderToSend.body.order?.items)
      ) {
        return
      }

      orderToSend.body.order.status = OrderHelper.getInitialOrderStatus(
        orderToSend.body.order
      )

      // Get SB Client
      const { sender, sbClient } = await ServiceBusHelper.createSender(
        process.env.REACT_APP_SB_PROCESS_ORDER ?? '',
        'processorder'
      )

      try {
        const batch = await sender.createMessageBatch()
        batch.tryAddMessage(orderToSend)
        if (batch !== null && batch.count > 0) {
          await sender.sendMessages(batch)
        }
        await sender.close()

        log(
          {
            type: LogType.CLICK,
            itemType: LogItemType.CHECKOUT,
            ...(!isNaN(orderToSend?.body?.order?.number) && {
              itemId: orderToSend?.body?.order?.number?.toString(),
            }),
            masterDealerId: selectedMasterDealerId,
            channel: selectedChannel,
          },
          KeycloakHelper.getToken(keycloak)
        )
      } catch (err: unknown) {
        await sender.close()
        throw new Error(err as string)
      } finally {
        await sbClient.close()
      }
      setOrderToSend({
        body: null,
        contentType: 'application/json',
      })
    }
    sendOrder()
  }, [orderToSend])

  useEffect(() => {
    if (sentOrder !== null) setLoading(false)
  }, [sentOrder])

  const processOrder2 = async (items: CartItems) => {
    if (isCartItemsEmpty(items) || loading) {
      return
    }

    setLoading(true)
    let hasError = false
    const { data: orderNumber } = await http.get<number>({
      url: '/order/nextNumber',
    })
    const masterDealerId = ChannelHelper.getMasterDealerId(
      selectedMasterDealerId,
      selectedChannel
    )

    let payments: Payment[] = []
    if (!state.context.isCoopRequest) {
      if (!isFreeOrder) {
        await CartHelper.processUnitedAPIOrderV2(
          orderNumber,
          user?.email,
          masterDealerId,
          items,
          state.context
        )
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((newPayments: any) => {
            payments = newPayments
            updateBalance()
          })
          .catch((error) => {
            const [errorDescription, errorTransactionDescription] = error as [
              string,
              string
            ]
            const errorStr =
              errorTransactionDescription &&
              errorTransactionDescription.trim() !== ''
                ? errorTransactionDescription
                : errorDescription
            setError(errorStr)
            setErrorHistory([...errorHistory, [errorStr, new Date()]])
            hasError = true
            setLoading(false)
          })
      }
    }

    if (hasError) {
      send({ type: 'MOVETOERROR' })
    } else {
      // Assemble Order
      const paymentTotal = payments.map((payment) => parseFloat(payment.amount))
      const order: Order = {
        number: orderNumber,
        status: state.context.isCoopRequest ? 6 : 1,
        paymentStatus: state.context.isCoopRequest
          ? 1
          : OrderHelper.getOrderPaymentStatus(
              items,
              paymentTotal.reduce((a, b) => a + b, 0),
              payments.length > 0
            ),
        customer: {
          user: user?.email,
          masterDealerId,
          billingAddress: state.context.billingAddress,
          shippingAddress: state.context.shippingAddress,
        },
        items: OrderHelper.getOrderItemsFromItems(items),
        payments: payments,
        history: [
          ...errorHistory.map(([error, date]) => ({
            description: `Error: ${error}`,
            date: date.toUTCString(),
          })),
          {
            description: 'Order placed on ZONE',
            date: new Date().toUTCString(),
          },
        ],
        createdBy: user?.email,
        createdOn: new Date().toUTCString(),
        coopAmount: coopRequestTotal,
      }
      setOrderItems(items)

      if (state.context.isCoopRequest) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const coopOrder: any = order
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        coopOrder.items = order.items.map((item: OrderItem) => {
          return {
            ...item,
            product: {
              ...item.product,
              productUrl: 'https://thedirectvmarketingzone.com',
            },
          }
        })

        await CartHelper.saveCartItems(coopOrder)
          .then(() => {
            send({ type: 'MOVETOCONFIRMATION' })
            setSentOrder(order)
          })
          .catch((err: string) => {
            setError(err)
            hasError = true
            send({ type: 'MOVETOERROR' })
          })
      } else {
        send({ type: 'MOVETOCONFIRMATION' })
        setOrderToSend({
          body: { order },
          contentType: 'application/json',
        })
        setSentOrder(order)
      }
      clearAllItems()
    }
  }

  useEffect(() => {
    const sendFreeDigitalOrder = async () => {
      if (items.products.every((x) => x.selectedDeliveryMethodIndex == 2)) {
        await processOrder2(items)
      }
    }

    if (
      (isFreeOrder && !(items.events.length > 0)) ||
      (items.events.length > 0 &&
        state.context.shippingAddress.contactName !== '')
    ) {
      sendFreeDigitalOrder()
    }
  }, [isFreeOrder])

  const displayContactInfosInp =
    items.events.length > 0 ||
    items?.products.some(
      (x: CartProduct) => x.selectedDeliveryMethodIndex !== 1
    )
  const displayShippingAddressInp = items?.products.some(
    (x: CartProduct) => x.selectedDeliveryMethodIndex == 1
  )

  const onSubmit = (address: OrderAddress) => {
    if (isFreeOrder || state.context.isCoopRequest) {
      send({
        type: 'SAVEORDER',
        shippingAddress: address,
      })
    } else {
      send({
        type: 'MOVETOEDITPAYMENT',
        shippingAddress: address,
      })
    }
  }

  return (
    <>
      {state.matches('error') ? (
        <S.Container>
          <S.InfoContainer>
            <S.Error dangerouslySetInnerHTML={{ __html: error }}></S.Error>
          </S.InfoContainer>
          <S.Button
            onClick={() =>
              state.context.isCoopRequest
                ? send({ type: 'RETURNTOCOOP' })
                : send({ type: 'RETURNTOEDITPAYMENT' })
            }
          >
            Return to Payment
          </S.Button>
        </S.Container>
      ) : state.matches('confirmation') ? (
        <S.Container>
          <S.InfoContainer>
            <SectionCheckoutConfirmation
              isCoopRequest={state.context.isCoopRequest}
              orderSent={sentOrder}
              shippingAddress={state.context.shippingAddress}
            />
          </S.InfoContainer>
          <SectionCheckoutConfirmationProducts
            items={orderItems}
            payments={sentOrder?.payments ?? []}
            isUsingCoop={state.context.isUsingCoop}
          />
        </S.Container>
      ) : items.products.length === 0 && items.events.length === 0 ? (
        <S.Container>No products in cart</S.Container>
      ) : (
        <S.Container>
          <S.InfoContainer>
            {state.matches('coOpEligibility') ? (
              <div>
                <SectionCheckoutCoop
                  items={items}
                  masterDealerId={ChannelHelper.getMasterDealerId(
                    selectedMasterDealerId,
                    selectedChannel
                  )}
                  onUseCoop={(coopAmount, isCoopRequest) =>
                    send({
                      type: 'MOVETOTOTALREVIEW',
                      isUsingCoop: true,
                      coopAmount,
                      isCoopRequest,
                    })
                  }
                  onIgnoreCoop={(coopAmount, isCoopRequest) =>
                    send({
                      type: 'MOVETOTOTALREVIEW',
                      isUsingCoop: false,
                      coopAmount,
                      isCoopRequest,
                    })
                  }
                />
              </div>
            ) : null}
            {state.matches('totalReview') ? (
              <div>
                <SectionCheckoutTotalReview
                  isCoopRequest={state.context.isCoopRequest}
                  items={items}
                  masterDealerId={ChannelHelper.getMasterDealerId(
                    selectedMasterDealerId,
                    selectedChannel
                  )}
                  isUsingCoop={state.context.isUsingCoop}
                  setCoopRequestTotal={setCoopRequestTotal}
                  onReturn={() => send({ type: 'RETURNTOCOOP' })}
                  onContinue={() =>
                    state.context.isCoopRequest
                      ? processOrder2(items)
                      : send({ type: 'MOVETOEDITSHIPPING' })
                  }
                />
              </div>
            ) : null}
            {state.matches('shipping') ? (
              <div>
                <SectionCheckoutShippingEdit
                  isFree={isFreeOrder}
                  address={state.context.shippingAddress}
                  displayShippingAddressInp={displayShippingAddressInp}
                  displayContactInfosInp={displayContactInfosInp}
                  coopRequest={state.context.isCoopRequest}
                  onSubmit={(address) => onSubmit(address)}
                />
              </div>
            ) : null}
            {state.matches('payment') ? (
              <div>
                <SectionCheckoutPaymentEdit
                  payment={{
                    billingAddress: state.context.billingAddress,
                    cardNumber: state.context.cardNumber,
                    expirationMonth: state.context.expirationMonth,
                    expirationYear: state.context.expirationYear,
                    CCV: state.context.CCV,
                  }}
                  onSubmit={(payment) => {
                    send({ type: 'MOVETOREVIEW', payment })
                  }}
                  onCheckCopyShipping={() => send({ type: 'COPYSHIPPING' })}
                  hideSameAsShippingCheckbox={displayShippingAddressInp}
                />
              </div>
            ) : null}
            {state.matches('review') ? (
              <>
                <SectionCheckoutShippingView
                  hasContactInfo={displayContactInfosInp}
                  hasShipping={displayShippingAddressInp}
                  address={state.context.shippingAddress}
                  onClickEdit={() => send('RETURNTOEDITSHIPPING')}
                />
                {!isFreeOrder && (
                  <SectionCheckoutPaymentView
                    payment={{
                      billingAddress: state.context.billingAddress,
                      cardNumber: state.context.cardNumber,
                      expirationMonth: state.context.expirationMonth,
                      expirationYear: state.context.expirationYear,
                      CCV: state.context.CCV,
                    }}
                    onClickEdit={() => send('RETURNTOEDITSHIPPING')}
                  />
                )}
                <S.Button
                  onClick={() => processOrder2(items)}
                  disabled={loading}
                >
                  Place Order
                </S.Button>
                {loading && <PageSpinner />}
              </>
            ) : null}
          </S.InfoContainer>
          {!state.matches('coOpEligibility') && (
            <S.ProductsContainer>
              <SectionCheckoutProducts
                isFreeOrder={isFreeOrder}
                items={items}
                isUsingCoop={state.context.isUsingCoop}
              />
            </S.ProductsContainer>
          )}
        </S.Container>
      )}
    </>
  )
}

export default Checkout
