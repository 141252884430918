import { CartProduct, CartItems, CartEvent } from '@monorepo/interfaces'
import {
  EventHelper,
  ProductHelper,
  CartHelper,
  useChannel,
  ChannelHelper,
  DealerHelper,
} from '@monorepo/infra'
import React, { useEffect, useState } from 'react'
import { Spinner } from '@monorepo/components'
import * as S from '../styles'

interface SectionCheckoutProductsProps {
  items: CartItems
  isUsingCoop: boolean
  isFreeOrder: boolean
}

export const SectionCheckoutProducts: React.FC<SectionCheckoutProductsProps> =
  ({ items, isUsingCoop, isFreeOrder }) => {
    const { selectedMasterDealerId, selectedChannel, coop } = useChannel()
    const [subtotal, setSubtotal] = useState(0)
    const [coopSubtotal, setCoopSubtotal] = useState(0)
    const [loadingCoopAmountsForCart, setLoadingCoopAmountsForCart] =
      useState(false)
    const [cartItemsWithCoop, setCartItemsWithCoop] = useState<CartItems>(items)

    useEffect(() => {
      if (
        (!DealerHelper.hasCoop(coop) && !isUsingCoop) ||
        CartHelper.isEmpty(items)
      ) {
        setCartItemsWithCoop(items)
        if (!isFreeOrder) {
          setSubtotal(CartHelper.getSubtotal(items))
        }
        return
      }

      setLoadingCoopAmountsForCart(true)
      CartHelper.getCoopTotalForCartV2(
        ChannelHelper.getMasterDealerId(
          selectedMasterDealerId,
          selectedChannel
        ),
        items
      )
        .then((cartItemsWithCoop) => {
          setCartItemsWithCoop(cartItemsWithCoop)
          setCoopSubtotal(CartHelper.getCoopUsedTotalForCart(cartItemsWithCoop))
          if (!isFreeOrder) {
            setSubtotal(CartHelper.getSubtotal(cartItemsWithCoop))
          }
        })
        .catch(() => {
          setCoopSubtotal(0)
          setSubtotal(CartHelper.getSubtotal(items))
        })
        .finally(() => {
          setLoadingCoopAmountsForCart(false)
        })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, selectedMasterDealerId, selectedChannel])

    if (items === null) return null

    return (
      <S.Container>
        <S.ProductList>
          {cartItemsWithCoop.products.map(
            (item: CartProduct, index: number) => {
              const {
                selectedVariantIndex,
                selectedDeliveryMethodIndex,
                selectedPrintAndShipIndex,
              } = item
              const variant = item.product.variants[selectedVariantIndex]
              return (
                <S.ProductItem key={`product-item-${index}`}>
                  <S.ThumbnailContainer>
                    <S.Thumbnail
                      src={variant.media[0]}
                      alt={item.product.title}
                    />
                  </S.ThumbnailContainer>
                  <S.ProductDetail>
                    <S.ProductRow>
                      <S.ProductTitle>{item.product.title}</S.ProductTitle>
                      <S.Text alignSelf="flex-start">
                        $
                        {isFreeOrder
                          ? '0'
                          : variant.printAndShipOptions[
                              selectedPrintAndShipIndex
                            ].price}
                      </S.Text>
                    </S.ProductRow>
                    {DealerHelper.hasCoop(coop) || isUsingCoop ? (
                      !loadingCoopAmountsForCart ? (
                        CartHelper.getCartItemCoopUsed(item) > 0 && (
                          <S.ProductRow>
                            <S.ProductTitle>CO-OP %</S.ProductTitle>
                            <S.Text alignSelf="flex-start">
                              {CartHelper.getCartItemCoopUsedPercentageText(
                                item
                              )}
                            </S.Text>
                          </S.ProductRow>
                        )
                      ) : (
                        <S.ProductRow>
                          <Spinner spinnerSize={4} />
                        </S.ProductRow>
                      )
                    ) : undefined}
                    <S.Text color={'#9fa8b3'}>
                      {ProductHelper.getDeliveryMethod(
                        item.product.deliveryMethods[
                          selectedDeliveryMethodIndex - 1
                        ],
                        variant.printAndShipOptions[selectedPrintAndShipIndex]
                          .pieceCount
                      )}
                    </S.Text>
                    <S.Text color={'#9fa8b3'}>
                      {ProductHelper.getVariantTitle(variant)}
                    </S.Text>
                  </S.ProductDetail>
                </S.ProductItem>
              )
            }
          )}
          {cartItemsWithCoop.events.map((item: CartEvent, index: number) => {
            return (
              <S.ProductItem key={`event-item-${index}`}>
                <S.ThumbnailContainer>
                  <S.Thumbnail
                    src={`${process.env.PUBLIC_URL}${EventHelper.getImageFile(
                      item.event.categories[0]
                    )}`}
                    alt={item.event.title}
                  />
                </S.ThumbnailContainer>
                <S.ProductDetail>
                  <S.ProductRow>
                    <S.ProductTitle>{item.event.title}</S.ProductTitle>
                    <S.Text alignSelf="flex-start">${item.event.price}</S.Text>
                  </S.ProductRow>
                  {DealerHelper.hasCoop(coop) || isUsingCoop ? (
                    !loadingCoopAmountsForCart ? (
                      CartHelper.getCartItemCoopUsed(item) > 0 && (
                        <S.ProductRow>
                          <S.ProductTitle>CO-OP %</S.ProductTitle>
                          <S.Text alignSelf="flex-start">
                            {CartHelper.getCartItemCoopUsedPercentageText(item)}
                          </S.Text>
                        </S.ProductRow>
                      )
                    ) : (
                      <S.ProductRow>
                        <Spinner spinnerSize={4} />
                      </S.ProductRow>
                    )
                  ) : undefined}
                  <S.Text color={'#9fa8b3'}>
                    {EventHelper.getLocation(item.event)}
                  </S.Text>
                </S.ProductDetail>
              </S.ProductItem>
            )
          })}
        </S.ProductList>
        <S.Line />
        {!loadingCoopAmountsForCart ? (
          <S.ProductRow>
            <S.ProductTitle>Subtotal</S.ProductTitle>
            <S.Text alignSelf="flex-start">${subtotal.toFixed(2)}</S.Text>
          </S.ProductRow>
        ) : (
          <S.ProductRow>
            <Spinner spinnerSize={4} />
          </S.ProductRow>
        )}
        {DealerHelper.hasCoop(coop) || isUsingCoop ? (
          !loadingCoopAmountsForCart ? (
            <S.ProductRow>
              <S.ProductTitle>Co-Op dollars to apply</S.ProductTitle>
              <S.Text alignSelf="flex-start">${coopSubtotal.toFixed(2)}</S.Text>
            </S.ProductRow>
          ) : (
            <S.ProductRow>
              <Spinner spinnerSize={4} />
            </S.ProductRow>
          )
        ) : undefined}
        <S.Line />
        <S.ProductRow>
          <S.TotalTitle>Total to be charged</S.TotalTitle>
          <S.TotalNumber>
            ${CartHelper.getSubtotalAfterCoop(cartItemsWithCoop).toFixed(2)}
          </S.TotalNumber>
        </S.ProductRow>
        {(DealerHelper.hasCoop(coop) || isUsingCoop) &&
        !loadingCoopAmountsForCart &&
        cartItemsWithCoop?.notEnoughCoop ? (
          <>
            <S.Line />
            <S.ProductRow>
              <S.PartialCoopDisclaimer>
                {CartHelper.partialCoopDisclaimer}
              </S.PartialCoopDisclaimer>
            </S.ProductRow>
          </>
        ) : undefined}
      </S.Container>
    )
  }
