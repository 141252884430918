import * as S from './styles'

import {
  ChannelHelper,
  MasterDealerAssociation,
  useChannel,
  formatterCurrencyUSD,
  DealerHelper,
} from '@monorepo/infra'
import { Icon, Spinner } from '@monorepo/components'
import { Link, useHistory } from 'react-router-dom'
import React, { useState } from 'react'
import { TableBody, TableRow } from '@material-ui/core'
import { UserHelper } from '@monorepo/infra'
import { colors } from '@monorepo/theme'

const CoopBar: React.FC = () => {
  const {
    selectedMasterDealerId,
    selectedChannel,
    associatedMasterDealerIds,
    coop,
    groups,
    setState,
  } = useChannel()
  const history = useHistory()
  const { isCoopUpdating } = coop
  const [showDialog, setShowDialog] = useState(false)

  const open = () => {
    setShowDialog(true)
  }

  const close = () => {
    setShowDialog(false)
  }

  const handleSelectChannel = (masterDealerId: string, channel: string) => {
    setState(masterDealerId, channel)
    close()
    history.push('/')
  }

  const getDialog = (amds: MasterDealerAssociation[]) => {
    const selected = amds?.find(
      (amd) => amd.masterDealerId === selectedMasterDealerId
    )

    if (!amds) return null
    return (
      <S.Dialog aria-label="Coop Dialog" isOpen={showDialog} onDismiss={close}>
        <S.CloseButton className="close-button" onClick={close}>
          x
        </S.CloseButton>
        <S.CurrentText>
          Current code:{' '}
          <S.CurrentTextCode>
            <S.CurrentTextCodeIdPart>
              {ChannelHelper.getMasterDealerId(
                selectedMasterDealerId,
                selectedChannel
              )}
            </S.CurrentTextCodeIdPart>
            {selected?.masterDealer[0].name}
          </S.CurrentTextCode>
        </S.CurrentText>
        <S.TableTitle>Select a different code</S.TableTitle>
        <S.TableContainer>
          <S.Table aria-label="Dealer Channels Table">
            <TableBody>
              {amds[0]?.masterDealer.map((md) =>
                amds[0].channels.map((channel, idx) => (
                  <TableRow key={`master-dealer-id-${idx}`}>
                    <S.TableCell>
                      {ChannelHelper.getMasterDealerId(
                        md.masterDealerId,
                        channel.toUpperCase()
                      )}
                    </S.TableCell>
                    <S.TableCell>{channel}</S.TableCell>
                    <S.TableCell>{md.name}</S.TableCell>
                    <S.TableCell>
                      <S.ChannelButton
                        onClick={() =>
                          handleSelectChannel(
                            md.masterDealerId,
                            channel.toUpperCase()
                          )
                        }
                        disabled={
                          md.masterDealerId === selectedMasterDealerId &&
                          channel.toUpperCase() === selectedChannel
                        }
                      >
                        {md.masterDealerId === selectedMasterDealerId &&
                        channel.toUpperCase() === selectedChannel
                          ? 'Selected'
                          : 'Select Channel'}
                      </S.ChannelButton>
                    </S.TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </S.Table>
        </S.TableContainer>
      </S.Dialog>
    )
  }

  const getCoopButton = (
    amds: MasterDealerAssociation[],
    masterDealerId: string,
    channel: string
  ) => {
    if (amds?.length === 1 && amds[0]?.channels?.length === 1) {
      return (
        <S.CoopNoButton>
          {ChannelHelper.getMasterDealerId(masterDealerId, channel)}
        </S.CoopNoButton>
      )
    }
    return (
      <>
        <S.CoopButton onClick={open}>
          {ChannelHelper.getMasterDealerId(masterDealerId ?? '', channel ?? '')}
          <Icon icon="Pencil" size={13} color={colors.lightGrey} />
        </S.CoopButton>
        {getDialog(amds)}
      </>
    )
  }

  return (
    <S.CoopBarContainer>
      <S.GlobalMessage></S.GlobalMessage>
      <S.CoopContainer>
        {getCoopButton(
          associatedMasterDealerIds,
          selectedMasterDealerId,
          selectedChannel
        )}
        {!isCoopUpdating ? (
          ChannelHelper.canSeeCoop(
            selectedMasterDealerId,
            selectedChannel,
            UserHelper.isDealerAdmin(groups)
          ) || DealerHelper.hasAllowance(coop) ? (
            `Co-Op ${formatterCurrencyUSD.format(DealerHelper.getCoop(coop))}`
          ) : null
        ) : (
          <Spinner spinnerSize={4} />
        )}
        <S.CoopSpacer>|</S.CoopSpacer>
        <Link to="/contact">Support</Link>
      </S.CoopContainer>
    </S.CoopBarContainer>
  )
}

export default CoopBar
