import { useState, useRef, useEffect } from 'react'
import { TextField, InputAdornment } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@material-ui/icons/Clear'
import { debounce } from 'lodash'
import { isFunction, isString } from '@monorepo/infra'

export type DebounceInputProps = {
  name?: string
  length?: number
  wait?: number
  onChange?: (value?: string, name?: string) => void
  fullWidth?: boolean
  placeholder?: string
  searchIcon?: boolean
  searchIconPosition?: 'start' | 'end'
  clearIcon?: boolean
  clearIconPosition?: 'start' | 'end'
  disabled?: boolean
  autoFocus?: boolean
}

const DebounceInput: React.FC<DebounceInputProps> = ({
  name = undefined,
  length = undefined,
  wait = undefined,
  onChange = undefined,
  fullWidth = false,
  placeholder = undefined,
  searchIcon = false,
  searchIconPosition = 'start',
  clearIcon = false,
  clearIconPosition = 'end',
  disabled = undefined,
  autoFocus = undefined,
}) => {
  const [value, setValue] = useState<string | undefined>('')

  const delayCall = useRef(
    debounce(
      (
        value?: string,
        name?: string,
        onChange?: (value?: string, name?: string) => void
      ) => {
        if (
          !isNaN(length as number) &&
          isString(value) &&
          value!.length > 0 &&
          value!.length < length!
        ) {
          return
        }

        if (isFunction(onChange)) {
          onChange?.(value, name)
        }
      },
      wait
    )
  ).current

  const handleChange = (value?: string) => {
    setValue(value)
    delayCall(value, name, onChange)
  }

  useEffect(() => {
    return () => {
      delayCall.cancel()
    }
  }, [delayCall])

  return (
    <TextField
      value={value}
      onChange={({ target: { value } }) => handleChange(value)}
      fullWidth={fullWidth}
      placeholder={placeholder}
      disabled={disabled}
      autoFocus={autoFocus}
      InputProps={{
        ...((searchIcon || clearIcon) && {
          startAdornment:
            (searchIcon && searchIconPosition === 'start') ||
            (clearIcon && clearIconPosition === 'start') ? (
              <InputAdornment position="start">
                {searchIcon ? (
                  <SearchIcon
                    onClick={() => handleChange(value)}
                    style={{ cursor: 'pointer' }}
                  />
                ) : clearIcon ? (
                  <ClearIcon
                    onClick={() => handleChange('')}
                    style={{ cursor: 'pointer' }}
                  />
                ) : null}
              </InputAdornment>
            ) : null,
          endAdornment:
            (searchIcon && searchIconPosition === 'end') ||
            (clearIcon && clearIconPosition === 'end') ? (
              <InputAdornment position="end">
                {searchIcon ? (
                  <SearchIcon
                    onClick={() => handleChange(value)}
                    style={{ cursor: 'pointer' }}
                  />
                ) : clearIcon ? (
                  <ClearIcon
                    onClick={() => handleChange('')}
                    style={{ cursor: 'pointer' }}
                  />
                ) : null}
              </InputAdornment>
            ) : null,
        }),
      }}
      sx={{
        '& .MuiInputBase-root': {
          'font-family': 'PFDINText',
          'font-size': '15px',
          'font-style': 'normal',
          'font-weight': '500',
          'line-height': '16px',
        },
      }}
    />
  )
}

export default DebounceInput
