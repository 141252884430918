import { Spinner } from '@monorepo/components'
import { CartHelper, DealerHelper, useChannel } from '@monorepo/infra'
import { CartItems } from '@monorepo/interfaces'
import React, { useEffect, useState } from 'react'
import * as S from '../styles'

interface SectionCheckoutTotalReviewProps {
  items: CartItems
  masterDealerId: string
  isUsingCoop: boolean
  isCoopRequest: boolean
  onReturn: () => void
  onContinue: () => void
  setCoopRequestTotal: (value: number) => void
}

export const SectionCheckoutTotalReview: React.FC<SectionCheckoutTotalReviewProps> =
  ({
    items,
    masterDealerId,
    isUsingCoop,
    isCoopRequest,
    onReturn,
    onContinue,
    setCoopRequestTotal,
  }) => {
    const { coop } = useChannel()
    const [coopSubtotal, setCoopSubtotal] = useState(0)
    const [loadingCoopAmountsForCart, setLoadingCoopAmountsForCart] =
      useState(false)
    const [cartItemsWithCoop, setCartItemsWithCoop] = useState<CartItems>(items)

    const subtotal = CartHelper.getSubtotal(items)

    useEffect(() => {
      if (!DealerHelper.hasCoop(coop) || CartHelper.isEmpty(items)) {
        setCartItemsWithCoop(items)
        return
      }

      setLoadingCoopAmountsForCart(true)
      CartHelper.getCoopTotalForCartV2(masterDealerId, items)
        .then((cartItemsWithCoop) => {
          setCartItemsWithCoop(cartItemsWithCoop)
          const coopTotal =
            CartHelper.getCoopUsedTotalForCart(cartItemsWithCoop)
          setCoopSubtotal(coopTotal)
          setCoopRequestTotal(coopTotal)
        })
        .catch(() => {
          setCoopSubtotal(0)
        })
        .finally(() => {
          setLoadingCoopAmountsForCart(false)
        })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coop.availableBalance, items, masterDealerId])

    return (
      <S.Container>
        <S.ProductRow>
          <S.ProductTitle>Total</S.ProductTitle>
          <S.Text alignSelf="flex-start">${subtotal.toFixed(2)}</S.Text>
        </S.ProductRow>
        {isUsingCoop || DealerHelper.hasCoop(coop) || isCoopRequest ? (
          <>
            <S.ProductRow>
              <S.ProductTitle>Total Co-Op Amount</S.ProductTitle>
              {loadingCoopAmountsForCart &&
              (isUsingCoop || DealerHelper.hasCoop(coop) || isCoopRequest) ? (
                <Spinner spinnerSize={4} />
              ) : (
                <S.Text alignSelf="flex-start">${coopSubtotal}</S.Text>
              )}
            </S.ProductRow>
          </>
        ) : null}
        <S.ProductRow>
          <S.ProductTitle>Total to be Charged to Credit Card</S.ProductTitle>
          {isUsingCoop || DealerHelper.hasCoop(coop) || isCoopRequest ? (
            !loadingCoopAmountsForCart ? (
              <S.Text alignSelf="flex-start">
                ${CartHelper.getSubtotalAfterCoop(cartItemsWithCoop).toFixed(2)}
              </S.Text>
            ) : (
              <Spinner spinnerSize={4} />
            )
          ) : (
            <S.Text alignSelf="flex-start">${subtotal.toFixed(2)}</S.Text>
          )}
        </S.ProductRow>
        <S.ProductRow style={{ gap: '15px' }}>
          <S.Button
            onClick={() => onReturn()}
            isSecondary={true}
            disabled={loadingCoopAmountsForCart && isUsingCoop}
          >
            Back
          </S.Button>
          <S.Button
            onClick={() => onContinue()}
            disabled={loadingCoopAmountsForCart && isUsingCoop}
          >
            {isCoopRequest ? 'Send Co-Op Request' : 'Continue'}
          </S.Button>
        </S.ProductRow>
      </S.Container>
    )
  }
