import * as S from './styles'
import { CoopAllowance, Dealer } from '@monorepo/interfaces'
import {
  ChannelHelper,
  MasterDealerAssociation,
  useChannel,
  DealerHelper,
  ReactSelectOptionType,
  emptyReactSelectOption,
  CoopAllowanceCapabilityType,
  formatterCurrencyUSD,
} from '@monorepo/infra'
import { TableBody } from '@material-ui/core'

import { Spinner } from '@monorepo/components'
import { DealerForm, InitialValuesParsed } from './forms/dealer'
import { DealerForm as IDealerForm } from '../../templates/TeamMembers'
import { useState } from 'react'
import { colors } from '@monorepo/theme'

export interface SelectableDealer extends Dealer {
  selected?: boolean
}

export interface SectionTeamMembersListProps {
  dealers: Dealer[] | SelectableDealer[]
  loading: boolean
  createDealer: (data: IDealerForm) => void
  deleteDealer: (userId: string, dealerId: string) => void
  editDealer: (data: IDealerForm) => void
  isAdmin: boolean
  dialog: {
    showEditDialog: boolean
    showCreateDialog: boolean
    showDeleteDialog: boolean
    setShowEditDialog: (toggle: boolean) => void
    setShowCreateDialog: (toggle: boolean) => void
    setShowDeleteDialog: (toggle: boolean) => void
  }
  error: string
  selectable?: boolean
  selectOne?: (id: string, selected: boolean) => void
}

const dealerType = (dealerGroup: string) => {
  switch (dealerGroup) {
    case '/dealerAdmin':
    case 'dealerAdmin':
      return 'Admin'
    case '/dealer':
    case 'dealer':
      return 'Member'
    default:
      return 'Unknown'
  }
}

const channelsList = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  associatedMasterDealers: any,
  coopAllowances: CoopAllowance[]
) => {
  if (
    !Array.isArray(associatedMasterDealers) ||
    !associatedMasterDealers.length
  )
    return null

  return (
    <S.ChannelsListContainer>
      {associatedMasterDealers.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (associatedMasterDealer: any, index: number) => {
          const channels = associatedMasterDealer.channels
          if (!Array.isArray(channels) || !channels.length) return null

          const masterDealerId = associatedMasterDealer.masterDealerId

          return (
            <S.WrapTags key={index}>
              {channels.map((channel: string, index: number) => {
                return (
                  <S.Tag
                    key={index}
                    label={channel}
                    backgroundColor={
                      coopAllowances?.find(
                        (item) =>
                          item.masterDealerId.trim().toUpperCase() ===
                            ChannelHelper.getMasterDealerId(
                              masterDealerId.trim().toUpperCase(),
                              channel.trim().toUpperCase()
                            ) &&
                          !isNaN(item?.allowance as number) &&
                          (item?.allowance as number) > 0
                      )
                        ? colors.green
                        : colors.lapis
                    }
                  />
                )
              })}
            </S.WrapTags>
          )
        }
      )}
    </S.ChannelsListContainer>
  )
}

const fillStatus = (groups: string[]) => {
  const hasDealerAdmin =
    groups.includes('/dealerAdmin') || groups.includes('dealerAdmin')
  const hasDealer = groups.includes('/dealer') || groups.includes('dealer')

  if (hasDealerAdmin || (hasDealerAdmin && hasDealer)) {
    return { value: '/dealerAdmin', label: 'Admin' }
  }

  if (hasDealer) {
    return { value: '/dealer', label: 'Member' }
  }

  return emptyReactSelectOption
}

const clockwiseOpenCircleArrow = '\u21bb'

const getCoopAllowanceText = (coopAllowance?: CoopAllowance, index?: number) =>
  !isNaN(coopAllowance?.allowance as number) &&
  (coopAllowance!.allowance as number) > 0 ? (
    <S.CoopAllowanceTextContainer key={index}>{`${
      ChannelHelper.splitMasterDealerId(coopAllowance!.masterDealerId)?.channel
    } ${formatterCurrencyUSD.format(coopAllowance!.allowance as number)}${
      coopAllowance?.capability ===
      CoopAllowanceCapabilityType.MONTHLY_RECURRING
        ? ` ${clockwiseOpenCircleArrow}`
        : ''
    }${
      coopAllowance?.capability ===
        CoopAllowanceCapabilityType.MONTHLY_RECURRING &&
      !isNaN(coopAllowance?.recurringAllowance as number) &&
      (coopAllowance!.recurringAllowance as number) > 0
        ? ` ${formatterCurrencyUSD.format(
            coopAllowance!.recurringAllowance as number
          )}`
        : ''
    }`}</S.CoopAllowanceTextContainer>
  ) : undefined

const getCoopAllowancesText = (coopAllowances?: CoopAllowance[]) =>
  Array.isArray(coopAllowances) && coopAllowances.length ? (
    <S.CoopAllowancesTextContainer>
      {coopAllowances.map((coopAllowance: CoopAllowance, index: number) =>
        getCoopAllowanceText(coopAllowance, index)
      )}
    </S.CoopAllowancesTextContainer>
  ) : undefined

const SectionTeamMembersList: React.FC<SectionTeamMembersListProps> = ({
  loading = true,
  dialog,
  dealers,
  createDealer,
  deleteDealer,
  editDealer,
  isAdmin,
  error,
  selectable,
  selectOne,
}) => {
  const [deleteUser, setDeleteUser] = useState({
    name: '',
    dealerId: '',
    userId: '',
  })
  const [editUser, setEditUser] = useState<InitialValuesParsed>({
    firstName: '',
    lastName: '',
    email: '',
    channels: null,
    types: null,
    status: emptyReactSelectOption,
    hasCoop: false,
    principalOf: undefined,
    userId: '',
    dealerId: '',
    coopAllowances: undefined,
    uniqueDealerId: undefined,
    businessName: undefined,
  })
  const [availableChannelOptions, setAvailableChannelOptions] = useState<
    { value: string; label: string }[]
  >([])
  const { associatedMasterDealerIds, selectedMasterDealerId } = useChannel()

  const channelsParsed = (channels: string[]) => {
    return channels.map((channel) => ({
      value: channel.toLowerCase(),
      label: channel.toUpperCase(),
    }))
  }

  const typesParsed = (types: string[]) => {
    return types.map((type) => ({
      value: type,
      label: type,
    }))
  }

  const getAvailableChannelOptions = (
    associatedMasterDealers: MasterDealerAssociation[]
  ): ReactSelectOptionType[] => {
    const availableChannels = ChannelHelper.getMasterDealerChannels(
      associatedMasterDealers
    )

    return ChannelHelper.getChannelOptions().filter((option) =>
      availableChannels.find(
        (availableChannel) =>
          availableChannel.toLowerCase() === option.value.toLowerCase()
      )
    )
  }

  const handleEditTeamMember = (index: number) => {
    const id = dealers[index]._id
    const dealer = dealers.filter((user: Dealer) => user._id === id)
    const channels =
      dealer[0].dealer.associatedMasterDealers[0].channels &&
      dealer[0].dealer.associatedMasterDealers[0].channels.length > 0
        ? dealer[0].dealer.associatedMasterDealers[0].channels
        : dealer[0].dealer.associatedMasterDealers[0].masterDealer[0].channels
    setEditUser({
      firstName: dealer[0].firstName,
      lastName: dealer[0].lastName,
      email: dealer[0].email || '',
      channels: channelsParsed(channels),
      types: typesParsed(
        dealer[0].dealer.associatedMasterDealers[0].dealerTypes ?? []
      ),
      status: fillStatus(
        DealerHelper.isPrincipal(dealer[0].dealer.principalOf)
          ? ['/dealerAdmin']
          : dealer[0].groups
      ),
      hasCoop:
        dealer[0].dealer.hasCoop ||
        DealerHelper.isPrincipal(dealer[0].dealer.principalOf),
      principalOf: dealer[0].dealer.principalOf,
      dealerId: dealer[0].dealer._id,
      userId: dealer[0]._id,
      coopAllowances: dealer[0].dealer.coopAllowances,
      uniqueDealerId: dealer[0].dealer.uniqueDealerId,
      businessName: dealer[0].dealer.businessName,
    })
    setAvailableChannelOptions(
      getAvailableChannelOptions(dealer[0].dealer.associatedMasterDealers)
    )
    dialog.setShowEditDialog(true)
  }

  const handleDeleteTeamMember = (index: number) => {
    const id = dealers[index]._id
    const dealer = dealers.filter((user: Dealer) => user._id === id)

    if (
      Array.isArray(dealer) &&
      DealerHelper.isPrincipal(dealer?.[0].dealer.principalOf)
    ) {
      alert("A principal can't be removed.")
      return
    }

    setDeleteUser({
      userId: dealer[0]._id,
      dealerId: dealer[0].dealer._id,
      name: `${dealer[0].firstName} ${dealer[0].lastName}`,
    })
    dialog.setShowDeleteDialog(true)
  }

  const close = () => {
    dialog.setShowCreateDialog(false)
    dialog.setShowEditDialog(false)
    dialog.setShowDeleteDialog(false)
  }

  return (
    <S.Container>
      <S.TableContainer>
        <S.Table aria-label="Products Table">
          <TableBody>
            {loading ? (
              <S.TbodyRowLoading>
                <S.TBodyCellLoading scope="row">
                  <Spinner spinnerSize={8} />
                </S.TBodyCellLoading>
              </S.TbodyRowLoading>
            ) : dealers.length === 0 ? (
              <S.EmptyMessage>
                There are no team members available or you are the only member
                of your team. Please check your search criteria if one was
                entered.
              </S.EmptyMessage>
            ) : (
              dealers?.map((dealer: SelectableDealer, index: number) => (
                <S.TbodyRow
                  key={index}
                  data-isprincipal={DealerHelper.isPrincipal(
                    dealer.dealer.principalOf
                  )}
                >
                  {selectable ? (
                    <S.TBodyCell scope="row">
                      <S.CheckboxContainer>
                        <input
                          type="checkbox"
                          key={dealer._id}
                          checked={dealer.selected}
                          onChange={(e) =>
                            selectOne?.(dealer._id as string, e.target.checked)
                          }
                        />
                      </S.CheckboxContainer>
                    </S.TBodyCell>
                  ) : undefined}
                  <S.TBodyCell scope="row">
                    <S.UserProfileBadge>
                      {dealer?.firstName?.charAt(0) ?? 'T'}
                    </S.UserProfileBadge>
                  </S.TBodyCell>
                  <S.TBodyCell scope="row">
                    <S.Name>{`${dealer.firstName} ${dealer.lastName}`}</S.Name>
                  </S.TBodyCell>
                  <S.TBodyCell scope="row">
                    <S.Email>{dealer.email}</S.Email>
                  </S.TBodyCell>
                  <S.TBodyCell scope="row">
                    {channelsList(
                      dealer.dealer.associatedMasterDealers,
                      dealer.dealer.coopAllowances
                    )}
                  </S.TBodyCell>
                  <S.TBodyCell scope="row">
                    <S.Group>{dealerType(dealer.groups[0])}</S.Group>
                  </S.TBodyCell>
                  <S.TBodyCell scope="row">
                    {getCoopAllowancesText(dealer.dealer.coopAllowances)}
                  </S.TBodyCell>
                  {isAdmin && (
                    <S.TBodyCell
                      scope="row"
                      onClick={() => handleEditTeamMember(index)}
                    >
                      <S.Link>Edit</S.Link>
                    </S.TBodyCell>
                  )}
                  {isAdmin && (
                    <S.TBodyCell
                      scope="row"
                      onClick={() => handleDeleteTeamMember(index)}
                    >
                      <S.Link>Remove</S.Link>
                    </S.TBodyCell>
                  )}
                </S.TbodyRow>
              ))
            )}
          </TableBody>
        </S.Table>
      </S.TableContainer>
      <S.TeamMembersDialog
        aria-label="Dialog"
        isOpen={dialog.showEditDialog}
        onDismiss={close}
      >
        <S.DialogContent aria-label="Content">
          <DealerForm
            initialValues={editUser}
            actionForm={editDealer}
            close={close}
            error={''}
            masterDealerId={selectedMasterDealerId}
            availableChannelOptions={availableChannelOptions}
          />
        </S.DialogContent>
      </S.TeamMembersDialog>
      <S.TeamMembersDialog
        aria-label="Dialog"
        isOpen={dialog.showCreateDialog}
        onDismiss={close}
      >
        <S.DialogContent aria-label="Content">
          <DealerForm
            actionForm={createDealer}
            close={close}
            error={error}
            masterDealerId={selectedMasterDealerId}
            availableChannelOptions={getAvailableChannelOptions(
              associatedMasterDealerIds
            )}
          />
        </S.DialogContent>
      </S.TeamMembersDialog>
      <S.TeamMembersDialog
        aria-label="Dialog"
        isOpen={dialog.showDeleteDialog}
        onDismiss={close}
      >
        <S.DialogContent aria-label="Content">
          <h2>{`Are you sure you want to delete the user ${deleteUser.name} ?`}</h2>
          <S.DialogActions>
            <S.ModalButton
              colorOption="stroke"
              label="CANCEL"
              onClick={() => close()}
            />
            <S.ModalButton
              colorOption="black"
              label="Remove user"
              onClick={() => {
                const { userId, dealerId } = deleteUser
                deleteDealer(userId, dealerId)
                close()
              }}
            />
          </S.DialogActions>
        </S.DialogContent>
      </S.TeamMembersDialog>
    </S.Container>
  )
}

export default SectionTeamMembersList
